import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Leftsiderbar from "../home/allcmp/Leftsidebar";
import MaterialTable from "material-table";
import { API_URL } from "../../../config";
import Top from "../Top";
import "../Products/product.css";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  NativeSelect,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import SuccessPop from "../SuccessPop/SuccessPop";
import ErrorPop from "../ErrorrPop/ErrorPop";
import DetailPopUp from "../Products/DetailPopUp";
import EditAffiliate from "./EditAffiliate";
import DeleteAffiliate from "./DeleteAffiliate";
import AffiliateViewModal from "./AffiliateViewModal";
import AffiliateOrderView from "./AffiliateOrderView";
import CopyToClipboard from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "black",
    fontSize: "15px",
  },
  bin1: {
    margin: "0px 0 !important",
    padding: "15px",
    width: "100%",
    border: "1px solid #818dcb7d !important",
    borderRadius: "8px ",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #818dcb7d !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px",
      fontSize: "15px",
      borderRadius: "10px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#818dcb !important",
        fontSize: "15px",
      },
    },
    "& textarea": {
      fontSize: "16px",
      color: "#818dcb7d !important",
      padding: "10px !important",
      fontWeight: "500",
    },
  },
  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#818dcb !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingBottom: "0px",
    wordBreak: "break-all",
  },
}));

const Affiliate = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [changeExpirydateModal, setChangeExpirydateModal] = useState(false);
  const handleAdd = () => setChangeExpirydateModal(!changeExpirydateModal);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openOrderView, setOpenOrderView] = useState(false);
  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => setOpenEdit(false);
  const handleEditPop = () => setOpenEdit(!openEdit);
  const handleViewPop = () => setOpenView(!openView);
  const handleOrderViewPop = () => setOpenOrderView(!openOrderView);
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleSuccesssPop = () => setOpenSuccess(!openSuccess);
  const [openDelete, setOpenDelete] = useState(false);
  const handleDeletePop = () => setOpenDelete(!openDelete);
  const handleDeleteOpen = () => setOpenDelete(true);

  const [openError, setOpenError] = useState(false);
  const handleErrorPop = () => setOpenError(!openError);
  const [openDetail, setOpenDetail] = useState(false);
  const handleDetail = () => setOpenDetail(!openDetail);
  const [selectCategroy, setSelectCategroy] = useState("Select Category");
  const [categroyData, setCategroyData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [message, setMessage] = useState("");
  const handleMesssage = (value) => setMessage(value);
  const [deleteId, setDeleteId] = useState("");
  const [description, setDescription] = useState("");
  const [filterSelect, setFilterSelect] = useState("0");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const onCopyText = () => {
    setCopied(true);
    setShowSnackbar(true)
    setTimeout(() => {
    setShowSnackbar(false)
    }, 3000);
  };

  const validationSchema = yup.object({
    firstName: yup.string().required("First name is required").trim(),
    lastName: yup.string().required("Last name is required").trim(),
    username: yup.string().required("Username is required").trim(),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required")
      .trim(),
    business: yup.string().trim(),
    city: yup.string().trim(),
    state: yup.string().trim(),
    country: yup.string().trim(),
    phone: yup.string().trim(),
    status: yup.string().trim(),
    comission: yup.string().required("Required").trim(),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      business: "",
      city: "",
      state: "",
      country: "",
      phone: "",
      status: "1",
      comission: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      //   let data = {
      //     question:values.question,
      //     answer:convertedContent,
      //     category:selectCategroy,
      //     status:1,
      //   }
      setLoading(true);
      try {
        const response = await axios.post(`${API_URL}/create/affiliate`, {
          ...values,
        });

        if (response.status === 201) {
          const resp = response.data;
          setLoading(false);
          handleMesssage("Affiliate Added Successfully..");
          getAffiliates(0);
          handleAdd();
          handleSuccesssPop();
          resetForm();
        }
      } catch (error) {
        if (error.response.status === 400 || error.response.status === 404) {
          handleMesssage(error.response.data.message);
          handleErrorPop();
          setLoading(false);
        } else {
          console.log("Network error");
          setLoading(false);
        }
      }
    },
  });


  useEffect(() => {
    if (filterSelect) {
      getAffiliates(filterSelect);
    } else {
      getAffiliates(0);
    }
  }, [filterSelect]);

  const getAffiliates = (filterSelect) => {
    let url = `${API_URL}/get/all/affiliate`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        let _temp = [];
        resp.affiliates.map((v, i) => {
          _temp.push({
            name: `${v.firstName} ${v.lastName}`,
            email: v.email,
            username: v.username,
            comission: `${v.comission} %`,
            action: (
              <p className="action-p">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Edit"
                  placement="top"
                >
                  <i
                    className="fa fa-edit action-icon"
                    onClick={() => handleEdit(v.id)}
                  ></i>
                </Tooltip>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Delete"
                  plac
                  ement="top"
                >
                  <i
                    className="fa fa-trash-o action-icon"
                    onClick={() => handleDelete(v.id)}
                  ></i>
                </Tooltip>

                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="View"
                  placement="top"
                >
                  <i
                    className={"fa fa-eye action-icon"}
                    onClick={() => handleView(v.id)}
                  ></i>
                </Tooltip>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title={copied?"Copied":'Click to copy affiliate link'}
                  placement="top"
                >
                    <Typography
                  component="span"
                  sx={{ color: "rgb(249 81 146)", cursor: "pointer" }}
                >
                  <CopyToClipboard
                    text={`https://e-campus-registration.bp.edu?referer=${v.username}`}
                    onCopy={onCopyText}
                  >
                 <i
                    className={"fa-solid fa-copy action-icon"}
                  ></i>
                  </CopyToClipboard>
                </Typography>
                 
                </Tooltip>
            
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title={'Orders'}
                  placement="top"
                >
                  <i
                    className={"fa-solid fa-dollar-sign action-icon"}
                    onClick={() => handleViewOrder(v.id)}
                  ></i>
                </Tooltip>
              </p>
            ),
          });
        });
        let revData = _temp.reverse();
        setData(revData);
      } else if (response.status == 401) {
        logout();
      } else {
         console.log("network error");
      }
    });
  };


  const handleEdit = (id) => {
    handleEditOpen();
    getAffiliateByID(id);
  };

  const getAffiliateByID = (id) => {
    fetch(API_URL + `/get/affiliate/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        setUpdateData(resp.affiliate);
      } else if (response.status == 401) {
        logout();
      } else {
         console.log("network error");
      }
    });
  };
  const getCountryList = (id) => {
    fetch(API_URL + `/countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
     setCountryList(resp.countries)
      } else {
        console.log("network error");
      }
    });
  };

  const getStateList = (id) => {
    fetch(API_URL + `/states?country_id=${formik.values.country}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        setStateList(resp.states)
      } else {
        console.log("network error");
      }
    });
  };
useEffect(()=>{
  getCountryList()
},[])
useEffect(()=>{
  getStateList()
},[formik.values.country])
  const handleDelete = (id) => {
    handleDeleteOpen();
    setDeleteId(id);
  };
  const handleView = (id) => {
    handleViewPop();
    getAffiliateByID(id);
  };
  const handleViewOrder = (id) => {
    handleOrderViewPop();
    getAffiliateByID(id);
  };

  const deleteFaq = () => {
    fetch(API_URL + `/delete/affiliate/${deleteId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        getAffiliates(0);
        handleDeletePop();
        handleMesssage("Affiliate Deleted Successfully..");
        handleSuccesssPop();
      })
      .catch((error) => {
        console.error("Error during DELETE request:", error);
      });
  };

  const handleAddFaq = () => {
    handleAdd();
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
        title: "Email",
        field: "email",
      },
    {
      title: "Username",
      field: "username",
    },
    {
      title: "Commission",
      field: "comission",
    },
    {
      title: "Action",
      field: "action",
    },
  ];

  const handleSelect = (event) => {
    setSelectCategroy(event.target.value);
  };

  const handleFilterSelect = (e) => {
    setFilterSelect(e.target.value);
  };

  const convertHtmlToText = (html) => {
    const tempElement = document.createElement("p");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };
  return (
    <div className="">
       <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
          "&.MuiSnackbar-root": {
            right: "50px",
          },
        }}
      >
        <Alert
          severity={"success"}
          style={{
            backgroundColor:"#818dcb",
            color: "white",
            fontSize: "17px",
            // fontFamily: "ABLE",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
        Link copied successfully..
        </Alert>
      </Snackbar>
      <div className="cmail">
        <Top />
      </div>
      <div className="wrp-dashbord">
        <div className="cemail-left">
          <Leftsiderbar />
        </div>
        <div className="right-section">
          <div className="onlineaccess-wrp">
            <div className="onlineaccess-child1">
              <h3>Affiliates</h3>
            </div>
            <div className="add-btn">
              <button onClick={handleAddFaq}>Add Affiliate</button>
            </div>
          </div>

          <div className="wrp-bankform" style={{ marginTop: "1.5rem" }}>
            <div className="mobiletable">
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  options={{
                    search: true,
                    showTitle: false,
                    toolbar: true,
                    pageSize: 10,
                    //  pageSize: data?.length <= 5 ? 5 : data?.length > 5 && data?.length <= 10 ? 10 : data?.length > 10 && data?.length <= 20 ? 20 : data?.length > 20 && data?.length <= 30 ? 30 : data?.length > 30 && data?.length <= 40 ? 40 : data?.length > 40 && data?.length <= 50 ? 50 : data?.length > 50 && data?.length <= 100 ? 100 : 150,
                    pageSizeOptions: [5, 10, 20, 50, 100, 150, 200],
                  }}
                  columns={columns}
                  data={data}
                  title=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={changeExpirydateModal}
        toggle={handleAdd}
        className="connect-boxSupport"
        centered={true}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={handleAdd}>
          <span className="ml-1 roititle">Add Affiliate</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    First Name<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="firstName"
                    name="firstName"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.firstName}
                    placeholder="Enter First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.firstName}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    Last Name<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="lastName"
                    name="lastName"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.lastName}
                    placeholder="Enter Last Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.lastName}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    Username<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="username"
                    name="username"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.username}
                    placeholder="Enter Username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.username && formik.errors.username && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.username}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    Email<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="email"
                    name="email"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.email}
                    placeholder="Enter Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.email}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    Commission (in %)<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="comission"
                    name="comission"
                    type="number"
                    className={classes.bin1}
                    value={formik.values.comission}
                    placeholder="Enter Commission"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.comission && formik.errors.comission && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.comission}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    Business Name
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="business"
                    name="business"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.business}
                    placeholder="Enter Business Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.business && formik.errors.business && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.business}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    City
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="city"
                    name="city"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.city}
                    placeholder="Enter City"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.city && formik.errors.city && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.city}
                    </Typography>
                  )}
                </Grid>
                
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    Country
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      border: "1px solid #818dcb7d",
                      mr: "0",
                      borderRadius: "5px ",
                      "& .MuiInputBase-root::before": {
                        borderBottom: "0 !important",
                      },
                      "& .MuiInput-root:hover::before": {
                        borderBottom: "0",
                      },
                      "& .MuiInputBase-root::after": {
                        borderBottom: "0",
                      },
                      "& select": {
                        p: "10px",
                      },
                      "& option": {
                        padding: "5px !important",
                        
                      },
                    }}
                  >
                    <NativeSelect
                      name="country"
                      id="Country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                    >
                     <option value="">--Select Country--</option>
                      {
                        countryList?.map((e,i)=>(
                          <option key={i} value={e?.id}>{e?.Name}</option>
                        ))
                      }
                    </NativeSelect>
                  </FormControl>
                  {formik.touched.country && formik.errors.country && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.country}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    State/Province/Others
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      border: "1px solid #818dcb7d",
                      mr: "0",
                      borderRadius: "5px ",
                      "& .MuiInputBase-root::before": {
                        borderBottom: "0 !important",
                      },
                      "& .MuiInput-root:hover::before": {
                        borderBottom: "0",
                      },
                      "& .MuiInputBase-root::after": {
                        borderBottom: "0",
                      },
                      "& select": {
                        p: "10px",
                      },
                      "& option": {
                        padding: "5px !important",
                      },
                    }}
                  >
                    <NativeSelect
                      name="state"
                      id="State"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                    >
                     <option value="">--Select State--</option>
                      {
                        stateList?.map((e,i)=>(
                          <option key={i} value={e?.id}>{e?.name}</option>
                        ))
                      }
                    </NativeSelect>
                  </FormControl>
                  {formik.touched.state && formik.errors.state && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.state}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    Phone No.
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="phone"
                    name="phone"
                    type="number"
                    className={classes.bin1}
                    value={formik.values.phone}
                    placeholder="Enter Phone Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.phone}
                    </Typography>
                  )}
                </Grid>
            
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#818dcb" }}
                  >
                    Status
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      border: "1px solid #818dcb7d",
                      mr: "0",
                      borderRadius: "5px ",
                      "& .MuiInputBase-root::before": {
                        borderBottom: "0 !important",
                      },
                      "& .MuiInput-root:hover::before": {
                        borderBottom: "0",
                      },
                      "& .MuiInputBase-root::after": {
                        borderBottom: "0",
                      },
                      "& select": {
                        p: "10px",
                      },
                      "& option": {
                        padding: "5px !important",
                      },
                    }}
                  >
                    <NativeSelect
                      name="status"
                      id="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                    >
                      <option value={""}>--Select--</option>
                      <option value={"1"}>Active</option>
                      <option value={"2"}>Inactive</option>
                    </NativeSelect>
                  </FormControl>
                  {formik.touched.status && formik.errors.status && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.status}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Box className={classes.btns} mt={"1.5rem"}>
                <Button type="submit" disabled={!formik.isValid}>
                
                    Submit{" "} {loading && <CircularProgress
                      sx={{
                        width: "12px !important",
                        height: "12px !important",
                        color: "#fff",
                      }}
                    />}
                </Button>
              </Box>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <EditAffiliate
        handleEditPop={handleEditPop}
        openEdit={openEdit}
        updateData={updateData}
        categroyData={categroyData}
        getFaq={getAffiliates}
        handleMesssage={handleMesssage}
        handleSuccesssPop={handleSuccesssPop}
        handleErrorPop={handleErrorPop}
        handleEditOpen={handleEditOpen}
        handleEditClose={handleEditClose}
      />
      <SuccessPop
        handleSuccesssPop={handleSuccesssPop}
        openSuccess={openSuccess}
        message={message}
      />
      <ErrorPop
        handleErrorPop={handleErrorPop}
        openError={openError}
        message={message}
      />
      <AffiliateViewModal
        updateData={updateData}
        openView={openView}
        handleViewPop={handleViewPop}
      />
      <AffiliateOrderView
        updateData={updateData}
        openView={openOrderView}
        handleViewPop={handleOrderViewPop}
      />
      <DetailPopUp
        handleErrorPop={handleDetail}
        openError={openDetail}
        message={description}
      />
      <DeleteAffiliate
        handleSuccesssPop={handleDeletePop}
        openSuccess={openDelete}
        deleteFaq={deleteFaq}
      />
    </div>
  );
};

export default Affiliate;
