import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Box,
  FormControl,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { API_URL } from "../../../config";
import AffiliateOrderTableRow from "./AffiliateOrderTableRow";

const AffiliateOrderView = ({ openView, handleViewPop, updateData }) => {
  const viewData = updateData?.[0];
  const [orderDetails, setOrderDetails] = useState([]);
  const getAffiliateByUsername = () => {
    fetch(API_URL + `/get/order/by/affiliate/${viewData?.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        setOrderDetails(resp.items);
      } else {
        setOrderDetails([]);
        console.log("network error");
      }
    });
  };
  useEffect(() => {
    if (viewData?.id) {
      getAffiliateByUsername();
    }
  }, [viewData?.username]);

  return (
    <>
      <Modal
        isOpen={openView}
        toggle={handleViewPop}
        className="connect-boxSupport"
        centered={true}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={handleViewPop}>
          <span className="ml-1 roititle">View Orders</span>
        </ModalHeader>
        <ModalBody>
          <Box
            className="changedate-box"
            sx={{
              "& p": {
                fontSize: "1rem",
                p: "10px",
                mb: "5px",
                border: "1px dashed #000",
                borderRadius: "5px",
              },
            }}
          >
            <Box
              sx={{
                // background:"transparent",
                color: "#000",
                //   backgroundImage: "linear-gradient(305deg, rgb(38 0 252 / 5%), rgb(255 0 234 / 9%))",
                //   border: "2px solid rgba(255, 255, 255, 0.22) !important",
                //   my:"4rem"
              }}
            >
              <TableContainer>
                <Table
                  aria-label="simple table"
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: "1px solid #21252933!important",

                      textAlign: "start",
                      "& th": {
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Order Id</TableCell>
                      <TableCell align="right">Order Date</TableCell>
                      <TableCell align="right">Total Amount</TableCell>
                      <TableCell align="right">Commission</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetails?.map((e, i) => (
                      <AffiliateOrderTableRow data={e} key={i} comission={viewData?.comission} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
                  {orderDetails?.length===0&&<Box fontSize={"18px"} textAlign={"center"} py={"2rem"}>Data not found.</Box>}
            </Box>
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AffiliateOrderView;
