import React, { Component, } from 'react';
import { BrowserRouter as Router, Route, Redirect, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/pages/home/home.js';
import Login from './components/pages/Login/Login';
import Privateroute from './components/pages/Privateroute';
import Affiliate from './components/pages/Affiliates/Affiliate';


class App extends Component {

	render() {
		return (
			<Router>
				<div>
					<Routes>

						<Route path="" element={<Privateroute />}>
							<Route path="/" element={<Affiliate />} />
						</Route>
						<Route path="/login" element={<Login />} />

					</Routes>
				</div>
			</Router>
		);
	}
}

export default App;
