import { Link, NavLink } from "react-router-dom";

function Leftsidebar() {
  const auth = localStorage.getItem("user_id");

  const TabArray = [

    {
      links: "/",
      name: "Affiliates",
      icon: <i class="fa-solid fa-user"></i>,
    },
 
  ];

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <div>
      <div className="left-list">
        <div className="list-wrp">
          <ul className="l-sideb-list">
        
            {TabArray.map((val, index) => {
              return (
                <li>
                  <NavLink to={val.links}>
                    {val.icon}
                    <span>{val.name}</span>
                  </NavLink>
                </li>
              );
            })}
            <li>
              {auth ? (
                <Link to="/login" onClick={logout}>
                  <i class="fa fa-sign-out" aria-hidden="true"></i>
                  <span>Logout</span>
                </Link>
              ) : null}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Leftsidebar;
