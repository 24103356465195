import React, { useEffect } from "react";

import { NavLink } from "react-router-dom";
// import logo from "../images/logo.png";

import burger from "../images/burger.png";
import crosss from "../images/crosss.png";

function Top() {
  const auth = localStorage.getItem("user_id");

  const TabArray = [
    {
      links: "/subscriber/user",
      name: "User Subscriptions",
      icon: <i class="fa-solid fa-user"></i>,
    },
    {
      links: "/",
      name: "Group Subscriptions",
      icon: <i class="fa-solid fa-list-check"></i>,
    },
    {
      links: "/manage/online/access",
      name: "Manage Online Access",
      icon: <i class="fa-solid fa-people-roof"></i>,
    },
    {
      links: "/products",
      name: "Products",
      icon: <i class="fa-solid fas fa-box"></i>,
    },
    {
      links: "/Categroys",
      name: "Product Categories",
      icon: <i class="fa-solid fas fa-box"></i>,
    },
    {
      links: "/Orders",
      name: "Orders",
      icon: <i class="fa-solid 	fas fa-boxes"></i>,
    },
    {
      links: "/faq",
      name: "FAQs",
      icon: <i class="fa-solid 	fa fa-question-circle"></i>,
    },
    {
      links: "/category",
      name: "FAQ Categories",
      icon: <i class="fa-solid 	fa fa-question-circle"></i>,
    },
  ];

  useEffect(() => {
    changePickupStoreMenu();

    function changePickupStoreMenu() {
      var body = $("body"),
        mask = $('<div class="mask"></div>'),
        toggleSlideRight = document.querySelector(".toggle-slide-right"),
        slideMenuRight = document.querySelector(".slide-menu-right"),
        activeNav = "";
      $("body").append(mask);

      /* slide menu right */
      toggleSlideRight.addEventListener("click", function () {
        $("body").addClass("smr-open");
        $(".mask").fadeIn();
        activeNav = "smr-open";
      });

      /* hide active menu if close menu button is clicked */
      $(document).on("click", ".close-menu", function (el, i) {
        $("body").removeClass(activeNav);
        activeNav = "";
        $(".mask").fadeOut();
      });
    }
  }, []);

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      <div className="db-hding">
        <div className="logo">
          {/* <Link to="/">
                        <img src={logo}></img>
                        </Link> */}
        </div>
        <h3>PROFESSIONAL SCHOOL DASHBOARD</h3>
        {/* <div className="logout-butn">

                    
                </div> */}
      </div>

      {/* mobile */}

      <div className="border-b">
        <div className="container-fluid">
          <div className="header-box">
            <div className="header-c1">
              {/* <div className="logo-box">
              <a href="/">
                <img src={logo} />
              </a>
            </div> */}
              <p>PROFESSIONAL SCHOOL DASHBOARD</p>
            </div>

            <div className="header-c2">
              <div className="burger-area">
                {/* <a href="#javascript:void(0)" className="burgers toggle-slide-right"> <img src={burger} /></a> */}
                <p className="burgers toggle-slide-right">
                  {" "}
                  <img src={burger} />
                </p>
              </div>
            </div>
          </div>

          <div className="menu slide-menu-right menu-list-wrp">
            <button class="close-menu">
              <img src={crosss} className="img-close" />
            </button>
            <ul className="menu-list2">
              <li>
                <ul className="l-sideb-list">
                  {TabArray.map((val, index) => {
                    return (
                      <li>
                        <NavLink
                          to={val.links}
                          // onClick={() => handleActive(index)}
                        >
                          {val.icon}
                          <span>{val.name}</span>
                        </NavLink>
                      </li>
                    );
                  })}

                  <li>
                    {auth ? (
                      <NavLink to="/login" class="close-menu" onClick={logout}>
                        {" "}
                        <i class="fa fa-sign-out" aria-hidden="true"></i>{" "}
                        <span>Logout</span>
                      </NavLink>
                    ) : null}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Top;
