import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { Box, Grid, Typography } from "@mui/material";
import OrderTable from "./OrderTable";

const OrderView = ({ openEdit, handleEditPop, dataById }) => {
  // console.log(dataById, "dataById")

  const [billData, setBillData] = useState();
  const [transactionData, setTransactionData] = useState();
  useEffect(() => {}, [dataById]);
  console.log(dataById);
  return (
    <>
      <Modal
        isOpen={openEdit}
        toggle={handleEditPop}
        // className="connect-boxorder"
        centered={true}
        // style={{maxWidth:"1100px"}}
      >
        <ModalHeader toggle={handleEditPop}>
          <span className="ml-1 roititle">Order Details</span>
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
              <Typography component={"hr"} />
              <Typography
                component={"h6"}
                sx={{
                  fontWeight: "600 !important",
                  py: "0.2rem",
                  // paddingLeft: "0.3rem",
                }}
              >
                User Details:-
              </Typography>
              <Typography component={"hr"} />
              <Typography
                component={"p"}
                sx={{
                  fontWeight: "600 !important",
                  textTransform: "capitalize",
                  "@media(max-width:600px)": { width: "100%" },
                }}
              >
                Name: {dataById.firstName} {dataById.lastName}
              </Typography>
              <Typography
                component={"p"}
                sx={{
                  fontWeight: "600 !important",

                  "@media(max-width:600px)": { width: "100%" },
                }}
              >
                Email: {dataById.email}
              </Typography>
              <Typography
                component={"p"}
                sx={{
                  fontWeight: "600 !important",
                  "@media(max-width:600px)": { width: "100%" },
                }}
              >
                Order Date: {new Date(dataById.createdAt).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} pt={"0"}>
              <Typography component={"hr"} />

              <Typography
                component={"h6"}
                sx={{
                  fontWeight: "600 !important",
                  py: "0.2rem",
                  // paddingLeft: "0.4rem",
                }}
              >
                Course Name:-
              </Typography>
              <Typography component={"hr"} />
              <Box sx={{ width: "100%", padding: "rem 0.4rem 0rem 0.4rem" }}>
                {dataById.coursecode} {dataById.courseName}
              </Box>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrderView;
