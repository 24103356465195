import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const DeleteAffiliate = ({ openSuccess, handleSuccesssPop, deleteFaq }) => {
  return (
    <>
      <Modal
        isOpen={openSuccess}
        toggle={handleSuccesssPop}
        centered={true}
        style={{ maxWidth: "480px !important" }}
      >
        <ModalHeader toggle={handleSuccesssPop}>
          <span className="ml-1 roititle">Delete</span>
        </ModalHeader>
        <ModalBody>
          <div className="">
            <Typography
              component={"p"}
              sx={{ textAlign: "center", padding: "1rem" }}
            >
              <i className="fa fa-trash-o delete-icons" />
            </Typography>
            <Typography
              component={"p"}
              sx={{ textAlign: "center", fontWeight: 600, padding: 0.4 }}
            >
              {" "}
              Are you sure?
            </Typography>
            <Typography component={"p"} sx={{ textAlign: "center" }}>
              {" "}
              Do you really want to delete this Affiliate? This process cannot be
              undone.
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                padding: "1.5rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "gray",
                  color: "white !important",
                  "&:hover": {
                    background: "gray",
                    color: "white !important",
                  },
                }}
                onClick={handleSuccesssPop}
              >
                No
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "#818dcb",
                  color: "white !important",
                  "&:hover": {
                    background: "#818dcb",
                    color: "white !important",
                  },
                }}
                onClick={deleteFaq}
              >
                Yes
              </Button>
            </Box>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeleteAffiliate;
